import React, { useState } from 'react';
import './card.css';

function App() {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleClick = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className="scene">
      <div 
        className={`card ${isFlipped ? 'flipped' : ''}`} 
        onClick={handleClick}
      >
        <div className="card__face card__face--front">
          <img src="card2.jpg" alt="Front" />
        </div>
        <div className="card__face card__face--back">
          <img src="card.jpg" alt="Back" />
        </div>
      </div>
    </div>
  );
}

export default App;
